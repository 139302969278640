import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

import bio1 from '../images/bio/bio1.jpg';
import bio2 from '../images/bio/bio2.jpg';

const BiographiePage = () => (
  <Layout>
    <SEO title="Biographie" />
    <Helmet bodyAttributes={{ class: 'biography' }} />
    <div className="staticContent" id="staticContent_biography">
      <h1>Biographie Leelo Pascal</h1>
      <h2>Un artiste d&#233;termin&#233; et passionn&#233;</h2>
      <p>
        <span className="imgFrame floatRight">
          <img
            alt="Leelo Pascal"
            height="135"
            src={bio1}
            width="191"
          />
        </span>
      </p>
      <p>
        D&#232;s l'&#226;ge de 5ans, Leelo Pascal a puis&#233; sa force dans un
        monde bien &#224; lui, peupl&#233; de sons, de couleurs et de
        m&#233;lodies qu'il fredonnait sans cesse. Complice de toujours, c'est
        la musique qui, &#224; chaque instant, a berc&#233; ses r&#234;ves les
        plus fous, jusqu'&#224; devenir l'axe principal de sa vie, support sur
        lequel il a tout mis&#233;.
      </p>
      <p>&#160;</p>
      <p>
        Entre 15 et 20 ans, il prend des cours au conservatoire, et devient
        batteur et chanteur. Il fait d'abord partie d'un groupe, puis s'oriente
        vers une carri&#232;re de chanteur solo.
      </p>
      <p>&#160;</p>
      <p>
        Etant passionn&#233; par tous les m&#233;tiers qui touchent la musique,
        il b&#226;tit de ses mains puis g&#232;re durant six ans, avec un
        associ&#233;, son propre studio d'enregistrement. Plus tard il l'a
        revendu pour se consacrer &#224; sa passion premi&#232;re&#160;: le
        chant.
      </p>
      <p>&#160;</p>
      <p>
        L'appel de la musique l'emmenant toujours plus loin, Leelo Pascal part
        &#224; la conqu&#234;te de SON monde musical. Il a alors cr&#233;&#233;
        son bagage en dirigeant diverses sessions d'enregistrements notamment en
        France et en Belgique. Il enregistre alors plusieurs d&#233;mos sur ses
        propres textes et se fait remarquer lors de divers spectacles.
      </p>
      <p>&#160;</p>
      <p>
        Mais la musique pulse dans son sang, tels d'incessants battements de
        tambours rythmant chacun de ses pas qui l'ont finalement emmen&#233;s
        jusqu'au Qu&#233;bec. Son respect absolu de la terre et de la nature
        humaine lui ont valu l'honneur d'&#234;tre accept&#233; par la nation
        am&#233;rindienne d'Ab&#233;nakise &#224; W&#244;linak, o&#249; il a
        v&#233;cu durant plusieurs ann&#233;es.
      </p>
      <p>&#160;</p>
      <p>
        <span className="imgFrame floatLeft">
          <img
            alt="Leelo Pascal"
            height="175"
            src={bio2}
            width="135"
          />
        </span>
      </p>
      <p>
        Actuellement, Leelo Pascal voyage entre le Canada et l'Europe, car
        Montr&#233;al lui a ouvert les bras et lui a permis de d&#233;couvrir
        une prodigieuse &#233;quipe d'auteurs, compositeurs et musiciens, ainsi
        que ses meilleurs studios. Tous sont convaincus par son talent et son
        sens artistique novateur.
      </p>
      <p>&#160;</p>
      <p>
        Leelo Pascal a une vraie pr&#233;sence. Son enthousiasme et sa foi
        in&#233;branlable en ce qu'il fait lui permettent d'aller toujours de
        l'avant. Sur son album &#171;&#160;Couleur Nature&#160;&#187;, il porte
        plusieurs &#171;&#160;casquettes&#160;&#187;. Il compose notamment un
        titre, co-&#233;crit 3 textes, dont&#160;: &#171;&#160;Mais que
        restera-t-il&#160;&#187; admirablement soulign&#233; par la puissance de
        son clip. De plus, son exp&#233;rience personnelle lui permet d'en
        &#234;tre le r&#233;alisateur et le directeur artistique de fa&#231;on
        mature et r&#233;fl&#233;chie.
      </p>
      <p>&#160;</p>
      <p>
        U2, Bon Jovi, Bruce Springsteen et tant d'autres artistes des
        ann&#233;es 70-80, font partie de ses influences gr&#226;ce aux
        m&#233;lodies rythm&#233;es de leurs titres mythiques. Ainsi, alliant
        ses id&#233;es propres et le talent de son &#233;quipe, cet artiste
        concr&#233;tise ses couleurs musicales.
      </p>
    </div>
  </Layout>
);

export default BiographiePage;
